import PropTypes from "prop-types"
import React from "react"
import Image from "../Image/image"
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import YellowDivider from "../YellowDivider/YellowDivider"
import classes from "./HeroSection.module.css"
import { IoIosArrowDown } from "react-icons/io"

const HeroSection = props => {
  const {
    sectionId,
    background,
    heroImageClass,
    imageFile,
    alt,
    headerText,
    displayText,
    bottomImage,
    bottomImageClass,
  } = props
  return (
    <React.Fragment>
      <section
        id={sectionId}
        className={classes.heroSectionRoot}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div
          className={
            heroImageClass ? classes.heroImageClass : classes.imageContainer
          }
        >
          <Image filename={"SuperiorTenderness"} alt={alt} />
        </div>
        {headerText && (
          <div className={classes.headerTextWrapper}>
            {headerText && <h1 className={classes.headerText}>{headerText}</h1>}
            <div className={classes.dividerContainer}>
              <YellowDivider color="yellow" headerDivider />
            </div>
            {displayText && (
              <div className={classes.textIconWrapper}>
                <p className={classes.displayText}>
                  {displayText.toUpperCase()}
                </p>
                <IoIosArrowDown className={classes.downArrow} />
              </div>
            )}
          </div>
        )}
        <div className={bottomImageClass}>
          <Image filename={bottomImage} />
        </div>
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  )
}

HeroSection.propTypes = {
  sectionId: PropTypes.string,
  background: PropTypes.object,
  imageFile: PropTypes.string,
  alt: PropTypes.string,
  headerText: PropTypes.string,
  displayText: PropTypes.string,
  bottomImage: PropTypes.string,
  bottomImageClass: PropTypes.string,
}

export default HeroSection
