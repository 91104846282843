import PropTypes from "prop-types"
import React from "react"
import VimeoPlayer from "react-player/lib/players/Vimeo"
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import YellowDivider from "../YellowDivider/YellowDivider"
import classes from "./BlackTextVideoSection.module.css"
import FadeIn from "../Animations/FadeIn/FadeIn"

const BlackTextVideoSection = props => {
  const { sectionId, displayText, indexPage, video } = props
  return (
    <React.Fragment>
      <section id={sectionId} className={classes.blackSectionRoot}>
        <div className={classes.backgroundContainer}>
          <FadeIn>
            <div
              className={
                indexPage ? classes.indexTextWrapper : classes.textWrapper
              }
            >
              <YellowDivider color="yellow" />
              <div className={classes.textContainer}>
                {displayText &&
                  displayText.map((item, index) => (
                    <p
                      key={index}
                      style={{ color: `${item.color ? item.color : null}` }}
                      className={indexPage ? classes.indexText : classes.text}
                    >
                      {item.text}
                      {displayText[index + 1] ? (
                        <>
                          <br />
                        </>
                      ) : null}
                    </p>
                  ))}
              </div>
              <YellowDivider color="yellow" />
            </div>
          </FadeIn>
        </div>
        {video && (
          <div className={classes.videoWrapper}>
            <VimeoPlayer
              url={video}
              width="100%"
              controls
              className={classes.videoContainer}
            />
          </div>
        )}
        <FullWidthYellowDivider />
      </section>
    </React.Fragment>
  )
}

BlackTextVideoSection.propTypes = {
  sectionId: PropTypes.string,
  displayText: PropTypes.array,
  video: PropTypes.string,
}

export default BlackTextVideoSection
