import classes from './YellowDivider.module.css';
import React from 'react';

const YellowDivider = ({ color, headerDivider }) => {
  return (
    <div
      className={
        [color === 'yellow' ? classes.yellowDivider : classes.blackDivider,
          headerDivider ? classes.headerDivider : null].join(" ")
      }
    />
  );
};

export default YellowDivider;
