import React from 'react'
import classes from './ParallaxSection.module.css'

const ParallaxSection = props => {
  const { sectionId, background, quoteText, quoteSignature } = props
  return (
    <section
      id={sectionId}
      className={classes.sectionRoot}
    >
      <div style={{ backgroundImage: `url(${background})` }} className={classes.parallaxDiv}/>
      {quoteText && (
        <div className={classes.quoteWrapper}>
          <div className={classes.quoteContainer}>
            <p className={classes.quoteText}>{quoteText}</p>
            <br />
            <p className={classes.quoteText}>{quoteSignature}</p>
          </div>
        </div>
      )}
    </section>
  )
}

export default ParallaxSection
